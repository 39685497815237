$(document).ready(function () {
    $('.print_deal_wrap').click(function () {
        $('.modal_wrap').addClass('active');
        $('.bg_modal').addClass('active');
    })
    $(document).on('click', '.js_close_modal', function () {
        $('.modal_wrap').removeClass('active');
        $('.bg_modal').removeClass('active');
        $('.js__add_file_modal').empty();
    })
    $(document).on('click', '.bg_modal', function () {
        $('.modal_wrap').removeClass('active');
        $('.bg_modal').removeClass('active');
        $('.js__add_file_modal').empty()
    })

    addFileInvoice();
})

const addFileInvoice = function() {
    $(function () {
        // preventing page from redirecting
        $("html").on("dragover", function (e) {
            e.preventDefault();
            e.stopPropagation();
            $(".js_image_load.info").text("Drag here");
        });

        $("html").on("drop", function (e) { e.preventDefault(); e.stopPropagation(); });

        // Drag enter
        $('.upload-area').on('dragenter', function (e) {
            e.stopPropagation();
            e.preventDefault();
            $(".js_image_load.info").text("Drop");
        });

        // Drag over
        $('.upload-area').on('dragover', function (e) {
            e.stopPropagation();
            e.preventDefault();
            $(".js_image_load.info").text("Drop");
        });

        // Drop
        $('.upload-area').on('drop', function (e) {
            e.stopPropagation();
            e.preventDefault();

            $(".js_image_load.info").text("Upload");

            var file = e.originalEvent.dataTransfer.files;
            var fd = new FormData();

            fd.append('file', file[0]);

            uploadData(fd);
        });

        // Open file selector on div click
        $("#uploadfile").click(function () {
            $("#file").click();
        });

        // file selected
        $("#file").change(function () {
            var fd = new FormData();
            var files = $('#file')[0].files[0];
            fd.append('file', files);

            uploadData(fd);
        });
    });

    // Sending AJAX request and upload file
    function uploadData(formdata) {
        $.ajax({
            url: routes.addFileInvoice,
            type: 'post',
            data: formdata,
            contentType: false,
            processData: false,
            dataType: 'json',
            success: function (response) {
                $('#file').addClass('photo_add');
                addThumbnail(response);
            }
        });
    }

    // Added thumbnail
    function addThumbnail(data) {
        let count = Number($('.js_count').text()) + 1;

        let file_name = data.file_name;
        let file_path = data.file_path;
        $('.js_list_file').append(`<li class="js__file_list file_list">
        <a target="_blank" href="${file_path}">${file_name}</a>
        <button type="button" class="js__remove_file" data-name="${file_name}">
            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 4.99326C2.27614 4.99326 2.5 5.21711 2.5 5.49326V16.4933H13.5V5.49326C13.5 5.21711 13.7239 4.99326 14 4.99326C14.2761 4.99326 14.5 5.21711 14.5 5.49326V17.4933H1.5V5.49326C1.5 5.21711 1.72386 4.99326 2 4.99326Z" fill="#4F4F4F"/>
                <path d="M0.5 2.49326C0.5 2.7694 0.723858 2.99326 1 2.99326H15C15.2761 2.99326 15.5 2.7694 15.5 2.49326C15.5 2.21711 15.2761 1.99326 15 1.99326H11.75V1.50671C11.75 0.954429 11.3023 0.506714 10.75 0.506714L5.25 0.506714C4.69772 0.506714 4.25 0.954429 4.25 1.50671V1.99326H1C0.723858 1.99326 0.5 2.21711 0.5 2.49326Z" fill="#4F4F4F"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 5.99326C5.27614 5.99326 5.5 6.21711 5.5 6.49326V14.4933C5.5 14.7694 5.27614 14.9933 5 14.9933C4.72386 14.9933 4.5 14.7694 4.5 14.4933V6.49326C4.5 6.21711 4.72386 5.99326 5 5.99326Z" fill="#4F4F4F"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 5.99326C8.27614 5.99326 8.5 6.21711 8.5 6.49326V14.4933C8.5 14.7694 8.27614 14.9933 8 14.9933C7.72386 14.9933 7.5 14.7694 7.5 14.4933V6.49326C7.5 6.21711 7.72386 5.99326 8 5.99326Z" fill="#4F4F4F"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 5.99326C11.2761 5.99326 11.5 6.21711 11.5 6.49326V14.4933C11.5 14.7694 11.2761 14.9933 11 14.9933C10.7239 14.9933 10.5 14.7694 10.5 14.4933V6.49326C10.5 6.21711 10.7239 5.99326 11 5.99326Z" fill="#4F4F4F"/>
            </svg>
        </button>
        <input type="hidden" value="${file_name}" name="file_${count}">
        </li>`);

        $('.js_count').text(count);
        $('.js_save_photo').show();
    }
}

window.addFileInvoice = addFileInvoice;