$(document).ready(function(){
    $(".available_viewing_pass_js").click(function(){
        $(".unavailable_pass_js").toggleClass("hidden")
        if ($('#password').attr('type') == 'password'){
            $('#password').attr('type', 'text');
        } else {
            $('#password').attr('type', 'password');
        }
        return false;
    })
    $(".come_login_form input").click(function(){
        $(".come_login_form input").css("background-color", "field");
    })

    $(".left_menu_item").click(function(){
        let menuItem = $(".left_menu_item").length;
        for(let i = 0; i < menuItem; i++){
            $(".left_menu_item").removeClass("active");
        }
        $(this).addClass("active");
    })
    $(".js__more_info_toggler").click(function(){
        $(".filling_categories_content").toggleClass("active")
    })
    $(".simple_report_chose_calendar_icon").click(function(){
        $(".simple_report_spec_month_wrap").toggleClass("active")
    })

    $(".hidden_menu_btn").click(function(){
        $(".page_menu").toggleClass("active")
        $(".page_menu_wrapper").toggleClass("active")
        $(".directory_page_head_wrapper").toggleClass("active")
        $(".directory_title_invoice_item_head").toggleClass("active")
        $(".hidden_menu_btn").toggleClass("active")
        $(".left_menu_wrapper").toggleClass("away")
        $(".left_menu_lang").toggleClass("active_small")
        $(".menu_title_icon").toggleClass("active")
        $(".menu_title").toggleClass("not_active")
        const logo = $(".menu_logo_image")
        const src = logo.attr("src")
        const dataSrc = logo.attr("data-src")
        logo.attr("src", dataSrc).attr('data-src', src)
        let changeWidth = $(".page_menu").outerWidth();
        $(".main-wrap").css("width", `calc(100% - ${changeWidth}px)`)
    })


    $(".header_burger_menu").click(function(){
        $(".page_menu_wrapper").toggleClass("js_open_burger");
        $(".header_burger_menu").toggleClass("active");
        $(".hidden_content_bg").toggleClass("active");
        $("body").toggleClass("body_hidden");
    })

    $('.directory_table_arrow_slide').click(function(){
        let item = $(this).closest('.js_wrap_row_suppliers').attr('data-item');
        $(this).closest('.js__handbook_items').find(`.js_children_item[data-item=${item}]`).slideToggle();
        $(this).children().toggleClass('rotate')
    })

    $(".hidden_content_bg").click(function(){
        $(".page_menu_wrapper").removeClass("js_open_burger");
        $(".header_burger_menu").removeClass("active");
        $(".hidden_content_bg").removeClass("active");
        $("body").removeClass("body_hidden");
    })

    function getHeight(){
        $('.js_row').each(function(item, i) {
            let index = $(this).attr("data-line");
            let height = $(this).outerHeight();
            $('.js_row_caption[data-line="'+index+'"]').css("height", height)
        });
    }
    getHeight();

    $("input#not_pdv_item_checkbox").change(function(){
        if ($(this).prop('checked')) {
            $('.chose_date_input_field_wrap_checked').fadeOut();
        } else {
            $('.chose_date_input_field_wrap_checked').fadeIn();
        }
    });

    $("input#transit_checkbox").change(function(){
        if ($(this).prop('checked')) {
            $('.filling_categories_wrap_checked').fadeIn();
        } else {
            $('.filling_categories_wrap_checked').fadeOut();
        }
    });

    $(".directory_paragraph_simple_report").click(function(){
        $(".simple_report_table_title").slideToggle();
        $('.directory_paragraph_simple_icon svg').toggleClass('rotate')
        $('.main-wrap').toggleClass('main-wrap_bg')
        $('.all_passports_page').toggleClass('mobile_filters')
    })

    $(".invoice_head_mob").click(function(){
        $(".simple_report_table_title").hide();
        $('.directory_paragraph_simple_icon svg').removeClass('rotate')
        $('.main-wrap').removeClass('main-wrap_bg')
        $('.all_passports_page').removeClass('mobile_filters')
    })


    $(window).on('scroll', function () {
        let heightEditsDealHead = $(".directory_title_filling_head_edit_deal").outerHeight();
        if ($(window).width() < 768 ) {
            $(".directory_title_filling_head").removeClass("fixed");
            $(".section_scroll_wrap").css('padding-top', '0px')
        } else{
            let scroll = $(window).scrollTop();
            if (scroll < 1) {
                $(".directory_title_filling_head").removeClass("fixed");
                $(".section_scroll_wrap").css('padding-top', '0px')
            } else {
                $(".section_scroll_wrap").css('padding-top', heightEditsDealHead)
                $(".directory_title_filling_head").addClass("fixed");
            }
        }
    });

    $(window).on('scroll', function () {
        let heightInvoiceHead = $(".directory_page_head_wrapper").outerHeight();
        if ($(window).width() < 992 ) {
            $(".directory_page_head_wrapper").removeClass("fixed");
            $(".all_passports_page").css('padding-top', '10px')
        } else{
            let scroll = $(window).scrollTop();
            if (scroll < 1) {
                $(".directory_page_head_wrapper").removeClass("fixed");
                $(".all_passports_page").css('padding-top', '10px')
            } else {
                $(".all_passports_page").css('padding-top', heightInvoiceHead)
                $(".directory_page_head_wrapper").addClass("fixed");
            }
        }
    });

    $(document).on('click', '.js__close_modal', function() {
        $('.modal_user_access').removeClass('js__open');
        $('.bg__modal').removeClass('active');
    })

    $(document).on('click', '.bg__modal', function() {
        $('.modal_user_access').removeClass('js__open');
        $('.bg__modal').removeClass('active');
    })

})
